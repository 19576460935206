var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s("Aircraft Maintenances")+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.browse,"search":_vm.search,"loading":_vm.loading,"loading-text":"LOADING.... PLEASE WAIT"},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.index)+" ")]}},{key:"item.aircraft",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.aircraft.serial_number)+" ")]}},{key:"item.aircraft_status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.aircraft.status)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.name === ("" + (_vm.$IYA.ACL.ROLE_DEV)) || item.name === ("" + (_vm.$IYA.ACL.ROLE_ADMIN)))?_c('span'):_c('span',[_c('v-btn',{attrs:{"depressed":"","text":"","color":"primary"},on:{"click":function($event){return _vm.read(item)}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1),_c('v-btn',{attrs:{"depressed":"","text":"","color":"error"},on:{"click":function($event){return _vm.trash(item.id)}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])],1)],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"1080px"},model:{value:(_vm.editMaintenance),callback:function ($$v) {_vm.editMaintenance=$$v},expression:"editMaintenance"}},[_c('v-card',{staticClass:"pa-15"},[_c('v-tabs',{attrs:{"color":"primary","left":""}},[_c('v-tab',[_vm._v("Details")]),_c('v-tab-item',[_c('aircraft-maintenance-read',{attrs:{"aircraft":_vm.maintenance},on:{"updateBrownse":_vm.updateBrownse}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }