<template>
  <v-card>
    <v-card-title>
      {{ "Aircraft Maintenances" }}
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="browse"
      :search="search"
      :loading="loading"
      loading-text="LOADING.... PLEASE WAIT"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.index="{ item }">
        {{ item.index }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.aircraft="{ item }">
        {{ item.aircraft.serial_number }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.aircraft_status="{ item }">
        {{ item.aircraft.status }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <span v-if="item.name === `${$IYA.ACL.ROLE_DEV}` || item.name === `${$IYA.ACL.ROLE_ADMIN}`"></span>
        <span v-else>
          <v-btn depressed text color="primary" @click="read(item)">
            <v-icon left dark>
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
          <v-btn depressed text color="error" @click="trash(item.id)">
            <v-icon left dark>
              {{ icons.mdiDelete }}
            </v-icon>
          </v-btn>
        </span>
      </template>
    </v-data-table>

    <v-dialog v-model="editMaintenance" max-width="1080px">
      <v-card class="pa-15">
        <v-tabs color="primary" left>
          <v-tab>Details</v-tab>
          <v-tab-item>
            <aircraft-maintenance-read
              :aircraft="maintenance"
              @updateBrownse="updateBrownse"
            ></aircraft-maintenance-read>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mdiPencil, mdiDelete } from "@mdi/js";
import Request from "@/core/api/request";
import permission from "@/directive/permission"; // Permission directive (v-permission)
import AircraftMaintenanceRead from "./AircraftMaintenanceRead.vue";

export default {
  name: "AircraftMaintenanceBrowse",
  directives: { permission },
  components: { AircraftMaintenanceRead },
  data() {
    return {
      loading: true,
      search: "",
      browse: [],
      headers: [
        { text: "NO.", value: "index", sortable: true },
        { text: "Aircraft SN", value: "aircraft", sortable: false },
        { text: "Aircraft Status", value: "aircraft_status", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Due At Hours", value: "due_at_hours", sortable: false },
        { text: "Due Date", value: "due_date", sortable: false },
        { text: "Action.", value: "actions", sortable: false },
      ],
      maintenance: {},
      editMaintenance: false,
      icons: {
        mdiPencil,
        mdiDelete,
      },
    };
  },

  computed: {
    perms() {
      return {
        read: this.$IYA.ACL.PERMISSION_USER_READ,
        add: this.$IYA.ACL.PERMISSION_USER_ADD,
        remove: this.$IYA.ACL.PERMISSION_USER_REMOVE,
      };
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const { data, status } = await Request.get(`${this.$IYA.URI.AIRCRAFT_MAINTENANCES__BROWSE}`);
      if (status !== this.$IYA.HTTP.OK) {
        this.loading = false;

        return;
      }
      if (data.data.length < 1) {
        this.loading = false;

        return;
      }
      data.data.forEach((item, i) => {
        item.index = i + 1;
      });
      this.browse = data.data;
      this.loading = false;
    },

    updateBrownse() {
      this.initialize();
    },

    read(param) {
      this.maintenance = param;
      this.editMaintenance = true;
    },

    trash(id) {
      this.$swal({
        title: `Are you sure?`,
        text: `You can't revert your action`,
        type: `warning`,
        showCancelButton: true,
        confirmButtonText: `Yes Delete it!`,
        cancelButtonText: `No, Keep it!`,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(result => {
        if (result.value) {
          this.destroy(id);
        } else {
          this.$swal(`Cancelled`, `Your records are still intact`, `info`);
        }
      });
    },

    async destroy(id) {
      await Request.post(`${this.$IYA.URI.AIRCRAFT_MAINTENANCES__DELETE}`, { id: id }).then(response => {
        if (response.data.success) {
          this.$swal(`Deleted`, `You successfully deleted this record`, `success`);
          this.initialize();
        } else {
          this.$swal(`Failed !`, `Failed to remove record`, `error`);
        }
      });
    },
  },
};
</script>
